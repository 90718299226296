import AwsS3 from "@uppy/aws-s3";
import Compressor from "@uppy/compressor";
import { Uppy, UppyFile } from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import ImageEditor from "@uppy/image-editor";
import "@uppy/image-editor/dist/style.css";
import { DragDrop, StatusBar, useUppy } from "@uppy/react";
import { useRef } from "react";
// import { useUploaderContext } from "src/contexts/UploaderContext";
import { Css } from "src/Css";
import { AssetResizing, useCreateAssetUploadUrlMutation } from "src/generated/graphql-types";

export interface UseUppyUploaderProps {
  allowedFileTypes?: Array<string>;
  /** This will emit once per uploaded file */
  onFinish: (file: UppyFile) => void;
  onError?: (file: UppyFile) => void;
  /** This will emit once when all files were uploaded */
  onComplete?: () => void;
  maxNumberOfFiles?: number;
  /** passed to Uppy, start uploading as soon as files are selected? */
  autoProceed?: boolean;
  /** request thumbnail previews for uploaded files */
  previewResizing?: AssetResizing;
}

export function useUppyUploader(props: UseUppyUploaderProps) {
  const { allowedFileTypes, onFinish, onComplete, onError, previewResizing, maxNumberOfFiles = null } = props;

  const onFinishRef = useRef<Function>();
  onFinishRef.current = onFinish;
  const onCompleteRef = useRef<Function>();
  onCompleteRef.current = onComplete;
  const onErrorRef = useRef<Function>();
  onErrorRef.current = onError;

  // const { setFiles, clearFiles } = useUploaderContext();
  const [createAssetUploadUrl] = useCreateAssetUploadUrlMutation();

  return useUppy(() => {
    const uppy = new Uppy({
      autoProceed: props.autoProceed ?? true,
      restrictions: {
        maxNumberOfFiles,
        minNumberOfFiles: 1,
        allowedFileTypes,
      },
    })
      .use(Compressor, { quality: 0.9 }) // This ensures correct rotation
      .use(ImageEditor, {
        quality: 1,
        cropperOptions: {
          croppedCanvasOptions: {},
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
        },
        actions: {
          granularRotate: true,
          revert: true,
          rotate: true,
          flip: false,
          zoomIn: false,
          zoomOut: false,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: false,
        },
      })
      .use(AwsS3, {
        getUploadParameters: async (file: UppyFile) => {
          const { data } = await createAssetUploadUrl({
            variables: { input: { contentType: file.type!, previewResizing: previewResizing || null } },
          });

          const { s3Key, uploadUrl: signedUrl, downloadUrl, previewUrl } = data?.createAssetUploadUrl!;
          file.meta = { ...file.meta, s3Key, signedUrl, downloadUrl, previewUrl };

          return {
            method: "PUT",
            url: signedUrl,
            fields: {},
            headers: { "Content-Type": file.type! },
          };
        },
      })
      .on("upload-progress", (file, progress) => {
        const progressPercentage = Math.round((progress.bytesUploaded * 100) / progress.bytesTotal);
        // setFiles({ [file.name]: { name: file.name, progress: progressPercentage } });
      })
      .on("upload-success", (file: UppyFile) => {
        onFinishRef.current?.(file);
      })
      .on("upload-error", (file, error) => {
        console.error(`error with file: ${file.name}, error message: ${error}`);
        onErrorRef.current?.(file);
      });
    uppy.on("complete", () => {
      onCompleteRef.current?.();
      // Once all files completed uploading (and total progress is 100 that means there is not pending retries) call reset
      // to return things to the way they were before any user input.
      // This to clear file list and allow user to upload same file multiple times
      if (uppy.getState().totalProgress === 100) {
        // Clear and close uploader after 5 secs of completing
        // setTimeout(() => clearFiles(), 5000);
        uppy.reset();
      }
    });
    return uppy;
  });
}

export interface UppyUploaderProps extends Omit<UseUppyUploaderProps, "autoProceed"> {
  /** Text that will show in the FileInput **/
  fileInputText?: string;
  /** Text that will show in the drop zone */
  dragDropText?: string;
  dragDropWidth?: number | string;
  dragDropHeight?: number | string;
}

export function UppyUploader(props: UppyUploaderProps) {
  const {
    dragDropText = "Drag & drop files",
    fileInputText = "Choose Files",
    allowedFileTypes,
    dragDropWidth = 250,
    dragDropHeight = 40,
  } = props;
  const uppy = useUppyUploader({ ...props, autoProceed: true });

  const isUploading = Object.keys(uppy.getState().currentUploads).length > 0;

  const parentWidth = typeof dragDropWidth === "number" ? `${dragDropWidth + 2}px` : `calc(${dragDropWidth} + 2px)`;
  const parentHeight = typeof dragDropHeight === "number" ? `${dragDropHeight + 2}px` : `calc(${dragDropHeight} + 2px)`;

  return (
    <div
      css={
        Css.ba.bBlue700.bgBlue50.bsDashed
          .borderRadius("8px")
          .if(!isUploading)
          .add({ width: parentWidth, height: parentHeight }).$
      }
    >
      <div
        css={{
          ...Css.df.fdc.aic.jcc.$,
          "& button": Css.df.jcc.aic.cursorPointer.blue700.bgBlue50.borderRadius("8px").mwPx(96).$,
          "& button:hover": Css.bgGray300.$,
          // Hide the icon that comes with uppy
          "& .uppy-DragDrop-arrow": Css.dn.$,
          "& .uppy-DragDrop-inner": Css.df.aic.jcc.if(!!dragDropText).gap1.$,
          // Add in our `upload` icon as a background-image.
          "& .uppy-DragDrop-inner::before": Css.wPx(24)
            .hPx(24)
            .add(
              "content",
              "url(\"data:image/svg+xml,%3Csvg aria-hidden='true' width='24' height='24' viewBox='0 0 24 24' fill='rgb(3, 105, 161)' xmlns='http://www.w3.org/2000/svg' data-icon='cloudUpload' data-testid='cloudUpload' id='cloudUpload'%3E%3Cpath d='M13 19V15H16L12 10L8 15H11V19H13Z'%3E%3C/path%3E%3Cpath d='M7 19H9V17H7C5.346 17 4 15.654 4 14C4 12.596 5.199 11.244 6.673 10.985L7.254 10.883L7.446 10.325C8.149 8.274 9.895 7 12 7C14.757 7 17 9.243 17 12V13H18C19.103 13 20 13.897 20 15C20 16.103 19.103 17 18 17H15V19H18C20.206 19 22 17.206 22 15C22 13.12 20.695 11.538 18.944 11.112C18.507 7.67 15.56 5 12 5C9.244 5 6.85 6.611 5.757 9.15C3.609 9.792 2 11.82 2 14C2 16.757 4.243 19 7 19Z'%3E%3C/path%3E%3C/svg%3E\")",
            ).$,
        }}
      >
        <DragDrop
          key={dragDropText} // force re-render to show current dragDropText
          uppy={uppy}
          height={dragDropHeight}
          width={isUploading ? "100%" : dragDropWidth}
          locale={{ strings: { dropHereOr: dragDropText } }}
        />
        <StatusBar uppy={uppy} hideUploadButton hideCancelButton hidePauseResumeButton />
      </div>
    </div>
  );
}
