import { UppyFile } from "@uppy/core";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Card, Icon, IconButton, SelectField, TextField, UppyUploader } from "src/components";
import { Css } from "src/Css";
import { CreateWarrantyItemTypeFragment, SaveWarrantyTicketItemInput } from "src/generated/graphql-types";
import { useTestIds } from "src/hooks";
import { AssetPreview } from "src/utils/AssetPreview";
import { SectionProps } from "../../CreateWarrantyPage";

type Item = Pick<SaveWarrantyTicketItemInput, "type" | "description" | "attachments">;
export type RequestDetailsProps = {
  itemTypes: CreateWarrantyItemTypeFragment[];
} & SectionProps;

export const RequestDetails = forwardRef(({ form, setForm, itemTypes }: RequestDetailsProps, ref) => {
  const [itemOpen, setItemOpen] = useState<number | undefined>(form.items.length - 1 ?? 0);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const items = form.items;

  // Initialize the form with one item
  useEffect(() => {
    if (items.length === 0) {
      setForm({
        ...form,
        items: [
          {
            type: itemTypes[0].code,
            description: "",
            attachments: [],
          },
        ],
      });
      setItemOpen(0);
    }
  }, [items]);

  useEffect(scrollToFirstErroredElement, [invalidFields]);

  // Validate form
  function validateForm(onSuccess: () => void) {
    const currentInvalidFields = getInvalidFields(items);
    if (currentInvalidFields.length === 0) {
      setInvalidFields([]);
      onSuccess();
    } else {
      setInvalidFields(currentInvalidFields);
    }
  }

  useImperativeHandle(ref, () => ({
    validate: () => validateForm(() => {}),
  }));

  function onAdd() {
    validateForm(() => {
      setForm({
        ...form,
        items: [
          ...form.items,
          {
            type: itemTypes[0].code,
            description: "",
            attachments: [],
          },
        ],
      });
      setItemOpen(form.items.length);
    });
  }

  function deleteItem(index: number) {
    const otherItems = form.items.filter((i, ii) => ii !== index);
    setForm({
      ...form,
      items: otherItems,
    });

    // update the open index
    if (itemOpen && index === itemOpen) {
      setInvalidFields([]);
    }
    if (itemOpen && index <= itemOpen) {
      setItemOpen(itemOpen - 1);
    }
  }

  return (
    <Card xss={Css.mbPx(60).bgWhitePure.pxPx(99).py6.relative.ifXs.py4.px3.$} isClickable={false}>
      <div css={Css.df.fdc.$}>
        {items.map((item, index) => {
          return (
            <>
              {index === itemOpen ? (
                <EditItemRow
                  onDelete={() => deleteItem(index)}
                  singleItem={items.length === 1}
                  itemTypes={itemTypes}
                  form={form}
                  item={item}
                  setForm={setForm}
                  index={index}
                  lastItem={index === items.length - 1}
                  onSave={() => validateForm(() => setItemOpen(undefined))}
                  invalidFields={invalidFields}
                />
              ) : (
                <ItemRow
                  singleItem={items.length === 1}
                  item={item}
                  onDelete={() => deleteItem(index)}
                  key={item.description}
                  itemTypes={itemTypes}
                  form={form}
                  index={index}
                  onEdit={() => validateForm(() => setItemOpen(index))}
                />
              )}
              {(index !== items.length - 1 || itemOpen !== items.length - 1) && (
                <div css={Css.hPx(1).bgGray200.w100.my5.$} />
              )}
            </>
          );
        })}

        <div css={Css.if(itemOpen === items.length - 1).mt3.$}>
          <div css={Css.f14.gray800.fw7.lh("24px").add("letterSpacing", "unset").$}>Add another issue</div>
          <div css={Css.f14.gray700.fw3.lh("22px").add("letterSpacing", "unset").mbPx(12).$}>
            If you're experiencing any additional warranty related issues, please add them here. You may request as many
            items as needed before submitting.
          </div>
          <Button size="small" variant="ghost" onClick={onAdd} width="none" iconLeft="plus">
            Add Item
          </Button>
        </div>
      </div>
    </Card>
  );
});

type ItemRowProps = {
  singleItem: boolean;
  item: Item;
  itemTypes: CreateWarrantyItemTypeFragment[];
  onDelete: () => void;
  onEdit: () => void;
  index: number;
} & Pick<SectionProps, "form">;

function ItemRow({ singleItem, item, onDelete, onEdit, itemTypes, form, index }: ItemRowProps) {
  return (
    <div css={Css.relative.$}>
      <div css={Css.absolute.right0.top0.df.gap1.$}>
        {!singleItem && (
          <Button variant="ghost" size="small" onClick={onDelete} iconLeft="trash" state="danger">
            Delete
          </Button>
        )}
        <Button variant="primary" size="small" onClick={onEdit}>
          Edit
        </Button>
      </div>
      <div css={Css.mb2.f18.lh("28px").add("letterSpacing", "0.36px").gray700.$}>Request ({index + 1})</div>
      <div css={Css.mb3.w100.$}>
        <div css={Css.f14.gray800.fw7.lh("24px").add("letterSpacing", "unset").$}>Request Type</div>
        <div css={Css.f14.gray700.fw3.lh("22px").add("letterSpacing", "unset").$}>
          {itemTypes.find((t) => t.code === item.type)!.name}
        </div>
      </div>
      <div css={Css.mb3.w100.$}>
        <div css={Css.f14.gray800.fw7.lh("24px").add("letterSpacing", "unset").$}>Description</div>
        <div css={Css.f14.gray700.fw3.lh("22px").add("letterSpacing", "unset").$}>{item.description}</div>
      </div>
      <div>
        <div css={Css.f14.gray800.fw7.lh("24px").add("letterSpacing", "unset").mb1.$}>Documentation</div>
        <div css={Css.overflowHidden.w100.absolute.$}>
          <div css={Css.nowrap.overflowXAuto.py1.$}>
            {(item.attachments || []).map((attachments) => (
              <div css={Css.wPx(100).hPx(100).mx1.dib.$}>
                <AssetPreview
                  asset={{
                    contentType: attachments.asset.contentType!,
                    id: attachments.asset.s3Key!,
                    __typename: "Asset",
                    downloadUrl: form.assetMap[attachments.asset.s3Key!],
                    attachmentUrl: form.assetMap[attachments.asset.s3Key!],
                    previewUrl: form.assetMap[attachments.asset.s3Key!],
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div css={Css.hPx(108).$} />
      </div>
    </div>
  );
}

type EditItemRowProps = {
  singleItem: boolean;
  lastItem: boolean;
  onDelete: () => void;
  itemTypes: CreateWarrantyItemTypeFragment[];
  item: Item;
  index: number;
  onSave: () => void;
  invalidFields: string[];
} & SectionProps;

function EditItemRow({
  onDelete,
  singleItem,
  lastItem,
  itemTypes,
  item,
  form: warrantyForm,
  setForm,
  index,
  onSave,
  invalidFields,
}: EditItemRowProps) {
  const [testId, descriptionTestId] = useTestIds("newItem", ["description"]);

  function saveAttachment(file: UppyFile) {
    const downloadUrl = file.meta.downloadUrl as string;
    setForm((prevForm) => ({
      ...prevForm,
      items: prevForm.items.map((i, ii) => {
        if (ii === index) {
          return {
            ...i,
            attachments: [
              ...(i.attachments || []),
              {
                id: undefined,
                parentId: undefined,

                asset: {
                  contentType: file.type,
                  fileName: file.name,
                  s3Key: file.meta.s3Key as string,
                  sizeInBytes: file.size,
                  delete: undefined,
                  id: undefined,
                },
              },
            ],
          };
        }
        return i;
      }),
      assetMap: {
        ...warrantyForm.assetMap,
        [file.meta.s3Key as string]: downloadUrl,
      },
    }));
  }

  function deleteAttachment(attachmentIndex: number) {
    setForm((prevForm) => ({
      ...prevForm,
      items: prevForm.items.map((i, ii) => {
        if (ii === index) {
          return {
            ...i,
            attachments: i.attachments?.filter((_, iii) => iii !== attachmentIndex),
          };
        }
        return i;
      }),
    }));
  }

  const attachmentsErrored = invalidFields.includes("attachments");

  return (
    <div>
      {!singleItem && (
        <div css={Css.df.aic.mb2.$}>
          {lastItem ? (
            <>
              <div css={Css.f18.gray800.lh("28px").add("letterSpacing", "0.36px").$}>Add Another Issue</div>
              <div css={Css.mla.$}>
                <Button variant="ghost" size="small" onClick={onDelete} state="danger">
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <>
              <div css={Css.f18.lh("28px").add("letterSpacing", "0.36px").gray700.$}>Request ({index + 1})</div>
              <div css={Css.mla.df.gap1.$}>
                <Button variant="ghost" size="small" onClick={onDelete} iconLeft="trash" state="danger">
                  Delete
                </Button>
                <Button variant="primary" size="small" onClick={onSave}>
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      <div css={Css.mb3.w100.$}>
        <SelectField
          options={itemTypes.map((t) => t.name)}
          selectedOptions={[itemTypes.find((t) => t.code === item.type)!.name]}
          onChange={([typeName]) => {
            const type = itemTypes.find((t) => t.name === typeName)!.code;
            setForm((prevForm) => ({
              ...prevForm,
              items: updateSingleItem(prevForm, index, { type }),
            }));
          }}
          label="Request Type"
          allowDeselect={false}
        />
      </div>

      <div
        css={Css.mb3.w100.add("scrollMarginTop", "92px").ifXs.add("scrollMarginTop", "112px").$}
        aria-invalid={invalidFields.includes("description")}
      >
        <TextField
          required
          value={item.description}
          onChange={(_, description) =>
            setForm((prevForm) => ({
              ...prevForm,
              items: updateSingleItem(prevForm, index, { description }),
            }))
          }
          label="Description"
          helperText="Please include the nature of the issue, the location in the home, and any additional information that may be helpful for evaluation"
          textArea
          rows={4}
          error={invalidFields.includes("description")}
          {...descriptionTestId}
        />
      </div>

      <div>
        {/* Would be cool to make this a Field component */}
        <div css={Css.mbPx(12).$}>
          <div
            css={
              Css.f14.gray800.fw7.lh("16px").add("letterSpacing", "0.32px").df.if(attachmentsErrored).bgRed600.whitePure
                .p1.wfc.$
            }
          >
            {attachmentsErrored && (
              <div css={Css.mrPx(4).df.aic.$}>
                <Icon icon="errorExclamation" size="16" viewBox="0 0 16 16" />
              </div>
            )}
            Documentation{attachmentsErrored && " Is required"}
          </div>
          <div css={Css.f14.gray700.fw3.lh("22px").add("letterSpacing", "unset").$}>
            Adding photos, videos, or any other documentation helps us understand the issue better
          </div>
        </div>

        <div
          css={
            Css.df.gap1.add("flexWrap", "wrap").add("scrollMarginTop", "92px").ifXs.add("scrollMarginTop", "112px").$
          }
          aria-invalid={attachmentsErrored}
        >
          {(item.attachments || []).map((attachment, attachmentIndex) => (
            <div css={Css.wPx(140).hPx(90).relative.$}>
              <IconButton
                onClick={() => deleteAttachment(attachmentIndex)}
                css={
                  Css.absolute.rightPx(2).topPx(2).z1.cursorPointer.important.pPx(4).mw("unset").mh("unset").h("unset")
                    .$
                }
              >
                <Icon icon="removeImage" size="16" viewBox="0 0 16 16" />
              </IconButton>
              <AssetPreview
                asset={{
                  contentType: attachment.asset.contentType!,
                  id: attachment.asset.s3Key!,
                  __typename: "Asset",
                  downloadUrl: warrantyForm.assetMap[attachment.asset.s3Key!],
                  attachmentUrl: warrantyForm.assetMap[attachment.asset.s3Key!],
                  previewUrl: warrantyForm.assetMap[attachment.asset.s3Key!],
                }}
              />
            </div>
          ))}
          <UppyUploader
            onFinish={saveAttachment}
            dragDropText=""
            maxNumberOfFiles={1}
            dragDropWidth={140}
            dragDropHeight={90}
          />
        </div>
      </div>
    </div>
  );
}

function updateSingleItem(prevForm: SectionProps["form"], index: number, item: Partial<Item>) {
  return prevForm.items.map((i, ii) => {
    if (ii === index) {
      return {
        ...i,
        ...item,
      };
    }
    return i;
  });
}

export function getInvalidFields(items: Item[]) {
  const invalidFields: string[] = [];
  items.forEach((item, index) => {
    if (!item.description) {
      invalidFields.push(`description`);
    }
    if (!item.attachments?.length) {
      invalidFields.push(`attachments`);
    }
  });
  return invalidFields;
}

export function scrollToFirstErroredElement() {
  const erroredElement = document.querySelector<HTMLElement>("[aria-invalid='true']");
  if (erroredElement) {
    erroredElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
