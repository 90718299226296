import { AuthenticatedRoute, AuthRouteProps, UnauthenticatedRoute } from "@homebound/auth-components";
import React from "react";
import { Switch } from "react-router-dom";
import { PageLayout } from "src/components/Nav";
import { HomeownerStages } from "src/utils";
import {
  Documents,
  FinanceDetails,
  Finances,
  Invoice,
  JourneyPage,
  MilestoneListPage,
  MilestonePage,
  NoteDetail,
  OverviewPage,
  Profile,
  RedirectComponent,
  Selections,
  SignIn,
  TaskPage,
} from "src/views";
import { GoogleCallback } from "src/views/Auth/GoogleCallback";
import { InboxPage } from "src/views/Inbox/InboxPage";
import { CreateWarrantyPage } from "src/views/Resources/CreateWarrantyPage";
import { ResourcesPage } from "src/views/Resources/ResourcesPage";
import { WarrantyTicketPage } from "src/views/Resources/WarrantyTicketPage";
import { V2Selections } from "src/views/Selections/V2Selections";

export type ProjectRouteProps = { projectId: string };
export type UnitDetailRoute = ProjectRouteProps & { unitId: string };
export type ActivityDetailRoute = ProjectRouteProps & { activityId: string; selectedTab: "progress" | "description" };
export type SpaceRouteProps = ProjectRouteProps & { spaceId: string };
export type SelectionRouteProps = SpaceRouteProps & { selectionId: string };
export type FinancesRouteProps = ProjectRouteProps & { selectedStage: HomeownerStages };
export type InvoiceRouteProps = ProjectRouteProps & { invoiceId: string };
export type SignInRouteProps = { linkCode?: string };
export type NoteRouteProps = ProjectRouteProps & { homeownerNoteId: string };
export type WarrantyTicketRouteProps = ProjectRouteProps & { warrantyTicketId: string };

export type MockLayoutType = {
  // For testing purposes
  mockLayout?: (component: React.ReactNode) => JSX.Element;
};

export const projectIdParam = ":projectId(p:\\d+|\\d+)";
// TODO: remove planning
const stageParam = ":selectedStage(planning|pre-construction|construction|warranty)?";
export const unitIdParam = ":unitId(u:\\d+|\\d+)";
export const spaceIdParam = ":spaceId(l:\\d+|\\d+)";
export const selectionIdParam = ":selectionId(hs:\\d+|\\d+)?";
const invoiceIdParam = ":invoiceId(inv:\\d+|\\d+)";
export const homeownerNoteIdParam = ":homeownerNoteId(hn:\\d+|\\d+)";
export const taskIdParam = ":taskId(t:\\d+|\\d+)";
export const warrantyTicketIdParam = ":warrantyTicketId(wt:\\d+|\\d+)";

const UNAUTHED_PATHS = ["/login", "/enterCode", "/verifyCode/:linkCode(\\d+)"];
export const REDIRECT_URL_KEY = "redirectUrl";

export default function Routes({ authProps, mockLayout }: AuthRouteProps & MockLayoutType) {
  const { isAuthenticated } = authProps;
  const layout =
    mockLayout ??
    function (component: JSX.Element, old = false) {
      return <PageLayout old={old}>{component}</PageLayout>;
    };

  // capture url to redirect to after login
  // don't put this in a useEffect()
  // otherwise it will fire after the redirect to login when unauthorized
  if (!isAuthenticated && !UNAUTHED_PATHS.includes(window.location.pathname)) {
    localStorage.setItem(REDIRECT_URL_KEY, window.location.pathname);
  }

  return (
    <Switch>
      <UnauthenticatedRoute path={UNAUTHED_PATHS} exact component={SignIn} authProps={authProps} />

      <UnauthenticatedRoute path="/auth/gcallback" exact component={GoogleCallback} authProps={authProps} />

      <AuthenticatedRoute path="/" exact component={RedirectComponent} authProps={authProps} />

      <AuthenticatedRoute
        path={inboxRoute(projectIdParam)}
        exact={true}
        render={() => layout(<InboxPage />)}
        authProps={authProps}
      />

      <AuthenticatedRoute path={journeyRoute()} exact component={RedirectComponent} authProps={authProps} />
      <AuthenticatedRoute
        path={journeyRoute(projectIdParam, stageParam)}
        exact={true}
        render={() => layout(<JourneyPage />)}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={milestonesRoute(projectIdParam)}
        exact={true}
        component={() => layout(<MilestoneListPage />)}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={milestoneRoute(projectIdParam, unitIdParam)}
        exact={true}
        component={() => layout(<MilestonePage />)}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={homeownerNoteRoute(projectIdParam, homeownerNoteIdParam)}
        exact={true}
        component={() => layout(<NoteDetail />)}
        authProps={authProps}
      />

      <AuthenticatedRoute path={overviewRoute()} exact component={RedirectComponent} authProps={authProps} />
      <AuthenticatedRoute
        path={overviewRoute(projectIdParam)}
        exact
        render={() => layout(<OverviewPage />)}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={milestoneTaskRoute(projectIdParam, unitIdParam, taskIdParam)}
        exact={true}
        component={() => layout(<TaskPage />)}
        authProps={authProps}
      />

      <AuthenticatedRoute
        path={resourcesRoute(projectIdParam)}
        exact={true}
        component={() => layout(<ResourcesPage />)}
        authProps={authProps}
      />
      <AuthenticatedRoute
        path={taskRoute(projectIdParam, homeownerNoteIdParam, taskIdParam)}
        exact={true}
        component={() => layout(<TaskPage />)}
        authProps={authProps}
      />

      <AuthenticatedRoute path={documentsRoute()} exact component={RedirectComponent} authProps={authProps} />
      <AuthenticatedRoute
        path={documentsRoute(projectIdParam)}
        exact
        render={() => layout(<Documents />)}
        authProps={authProps}
      />

      <AuthenticatedRoute path={teamRoute()} exact component={RedirectComponent} authProps={authProps} />
      <AuthenticatedRoute
        path={teamRoute(projectIdParam)}
        exact
        render={() => layout(<ResourcesPage initialTab="Your Team" />)}
        authProps={authProps}
      />

      <AuthenticatedRoute path={profileRoute()} exact component={RedirectComponent} authProps={authProps} />
      <AuthenticatedRoute
        path={profileRoute(projectIdParam)}
        exact
        render={() => layout(<Profile {...authProps} />)}
        authProps={authProps}
      />

      {/* Design Studio */}
      <AuthenticatedRoute path={spacesRoute()} exact component={RedirectComponent} authProps={authProps} />
      <AuthenticatedRoute
        path={spacesRoute(projectIdParam)}
        render={() => <Selections mockLayout={mockLayout} />}
        authProps={authProps}
      />

      <AuthenticatedRoute
        path={v2SelectionsRoute(projectIdParam)}
        render={() => <V2Selections />}
        authProps={authProps}
      />

      {/* Finances */}
      <AuthenticatedRoute path={financesRoute()} exact component={RedirectComponent} authProps={authProps} />

      <AuthenticatedRoute
        path={financesDetailsRoute(projectIdParam, stageParam)}
        exact
        render={() => layout(<FinanceDetails />)}
        authProps={authProps}
      />

      <AuthenticatedRoute
        path={financesRoute(projectIdParam, stageParam)}
        exact
        render={() => layout(<Finances />)}
        authProps={authProps}
      />

      <AuthenticatedRoute
        path={invoiceRoute(projectIdParam, invoiceIdParam)}
        render={() => layout(<Invoice />, true)}
        authProps={authProps}
      />

      <AuthenticatedRoute
        path={warrantyTicketRoute(projectIdParam, warrantyTicketIdParam)}
        render={() => layout(<WarrantyTicketPage />)}
        authProps={authProps}
      />

      <AuthenticatedRoute
        path={warrantyRoute(projectIdParam)}
        render={() => layout(<ResourcesPage initialTab="Warranty" />)}
        authProps={authProps}
      />

      <AuthenticatedRoute
        path={createWarrantyRoute(projectIdParam)}
        render={() => layout(<CreateWarrantyPage {...authProps} />)}
        authProps={authProps}
      />

      {/* Gives the Redirect component access to the projectId in params */}
      <AuthenticatedRoute path={projectRoute(projectIdParam)} component={RedirectComponent} authProps={authProps} />

      {/* For now, 404's will redirect to the default project Overview page */}
      <AuthenticatedRoute component={RedirectComponent} authProps={authProps} />
    </Switch>
  );
}

/** Route helpers */
const projectRoute = (projectId?: string) => (projectId ? `/project/${projectId}` : "");

export const overviewRoute = (projectId?: string) => `${projectRoute(projectId)}/overview`;
export const inboxRoute = (projectId?: string) => `${projectRoute(projectId)}/inbox`;
export const journeyRoute = (projectId?: string, selectedTab?: HomeownerStages | string) =>
  projectId ? `${projectRoute(projectId)}/journey${selectedTab ? `/${selectedTab}` : ""}` : "/journey";

export const milestoneRoute = (projectId: string, unitId: string) =>
  `${projectRoute(projectId)}/journey/milestone/${unitId}`;

export const milestonesRoute = (projectId: string) => `${projectRoute(projectId)}/journey/milestone`;

export const milestoneTaskRoute = (projectId: string, unitId: string, taskId: string) =>
  `${projectRoute(projectId)}/journey/milestone/${unitId}/task/${taskId}`;

export const homeownerNoteRoute = (projectId: string, homeownerNoteId: string) =>
  `${projectRoute(projectId)}/journey/note/${homeownerNoteId}`;

export const resourcesRoute = (projectId?: string) => `${projectRoute(projectId)}/resources`;

export const taskRoute = (projectId: string, homeownerNoteId: string, taskId: string) =>
  `${projectRoute(projectId)}/journey/note/${homeownerNoteId}/task/${taskId}`;

export const documentsRoute = (projectId?: string) => `${projectRoute(projectId)}/documents`;

export const teamRoute = (projectId?: string) => `${projectRoute(projectId)}/team`;

export const profileRoute = (projectId?: string) => `${projectRoute(projectId)}/profile`;

export const designStudioRoute = (projectId?: string) => `${projectRoute(projectId)}/studio`;

export const spacesRoute = (projectId?: string) => `${designStudioRoute(projectId)}/selections/spaces`;

export const selectionRoute = (projectId?: string, spaceId?: string, selectionId?: string) =>
  `${spacesRoute(projectId)}${spaceId ? `/${spaceId}` : ""}/selection/${selectionId ?? ""}`;

export const financesRoute = (projectId?: string, selectedStage?: HomeownerStages | string) =>
  `${projectRoute(projectId)}/finances/${selectedStage ?? ""}`;

export const invoiceRoute = (projectId?: string, invoiceId?: string) =>
  `${projectRoute(projectId)}/finances/invoice/${invoiceId}`;

export const financesDetailsRoute = (projectId?: string, selectedStage?: HomeownerStages | string) =>
  `${projectRoute(projectId)}/finances/${selectedStage}/details`;

export const warrantyRoute = (projectId: string) => `${projectRoute(projectId)}/warranty`;

export const warrantyTicketRoute = (projectId: string, warrantyTicketId: string) =>
  `${projectRoute(projectId)}/warranty/${warrantyTicketId}`;

export const createWarrantyRoute = (projectId?: string) => `${projectRoute(projectId)}/warranty-request/create`;

// checkoutCustomerConfigId is the same pKey used in personalization airtable `customer_configuration` table and personalization db `customer_configurations` table
export const v2SelectionsRoute = (projectId: string) => `${projectRoute(projectId)}/selections`;
