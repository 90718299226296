// organize-imports-ignore
import "src/globalThis";
import { ApolloProvider } from "@apollo/client";
import { css, Global } from "@emotion/react";
import * as FullStory from "@fullstory/browser";
import { CssBaseline } from "@material-ui/core";
import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import Amplify from "aws-amplify";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { globalStyles, muiTheme } from "src/theme";
import { QueryParamProvider } from "use-query-params";
import { App } from "./App";
import { amplifyConfig, configureDatadog, createApolloClient, getStage, initializeGoogleAnalytics } from "./context";
import * as serviceWorker from "./serviceWorker";

const stage = getStage();
configureDatadog(stage);

FullStory.init({
  orgId: "P2TFT",
});

initializeGoogleAnalytics();

// Wait until the initial CMS data load is complete before rendering the app
createApolloClient().then((apolloClient) => {
  Amplify.configure(amplifyConfig(stage));
  //Amplify.Logger.LOG_LEVEL = "DEBUG";

  ReactDOM.render(
    // injects MUI styles first, so emotion styles will override them
    <StylesProvider injectFirst>
      <ApolloProvider client={apolloClient}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <MuiThemeProvider theme={muiTheme}>
              <Global
                styles={css`
                  ${globalStyles}
                `}
              />
              <CssBaseline />
              <App />
            </MuiThemeProvider>
          </QueryParamProvider>
        </Router>
      </ApolloProvider>
    </StylesProvider>,
    document.getElementById("root"),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
