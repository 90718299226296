import { AuthViewProps, listenForAuthEvent } from "@homebound/auth-components";
import React, { useEffect, useState } from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

export interface GoogleCallbackProps extends Pick<AuthViewProps, "setUser" | "setIsAuthenticated">, RouteProps {}

export function GoogleCallback(props: GoogleCallbackProps) {
  const [handlingRequest, setHandlingRequest] = useState(true);

  const [{ error_description }] = useQueryParams({ error_description: StringParam });

  const history = useHistory();

  useEffect(() => {
    waitForTokenAuth();
    // eslint-disable-next-line
  }, []);

  function redirectToSignInWithError(errorMessage: string) {
    const params = new URLSearchParams();
    params.append("signInError", errorMessage);
    history.push("/login?" + params);
  }

  async function waitForTokenAuth() {
    // we may get an error directly from the callback from cognito, and
    // amplify will not attempt to exchange the token, which is what
    // the listenForAuthEvent below would catch.
    // We should probably move this logic to the listenForAuthEvent, too
    if (error_description) {
      redirectToSignInWithError(error_description);
      return;
    }

    const { data: user, error } = await listenForAuthEvent();
    setHandlingRequest(false);

    if (user) {
      props.setUser(user);
      props.setIsAuthenticated(true);
    } else {
      console.log("Error", error);
      if (error) {
        redirectToSignInWithError(error.message);
      }
    }
  }

  return handlingRequest ? (
    <span>Loading...</span>
  ) : (
    // should be redirected after code succeeds or fails
    <div />
  );
}
